.container-doctor-detail {
    padding: 50px 100px;
}
.description {
    padding: 50px 100px;
    background-color: #fff;
    display: flex;
    justify-content: space-between;
    padding: 0 100px;
    width: 100%;
    .appointment-doctor {
        width: 33.33333%;
        margin-right: 80px;
        .make-an-appointment {
            width: 360px;
            height: 380px;
            margin-top: 50px;
            border: 1px solid #cacaca;
            border-radius: 12px;
            padding: 30px;
            position: relative;
            text-align: center;
            .make-an-appointment-1 {
                width: 80px;
                height: 80px;
                background-image: url("../../assets/images/appointment.png");
                background-size: contain;
                margin: 10px auto 30px;
            }
            .make-an-appointment-2 {
                font-family: Cambria, Cochin, Georgia, Times, "Times New Roman", serif;
                color: #3370bc;
                font-size: 1.5rem;
                font-weight: 600;
                margin-bottom: 20px;
            }
            .make-an-appointment-3 {
                color: #707573;
                font-size: 1.25rem;
            }
            .make-an-appointment-4 {
                background-color: transparent;
                border: 2px solid #e6495e;
                cursor: pointer;
                width: auto;
                padding: 10px 25px;
                margin: 20px auto 0;
                text-transform: uppercase;
                border-radius: 999px;
                color: #e6495e;
                font-size: 1.25rem;
                font-weight: 600;
                transition: all 0.2s linear;
                &:hover {
                    background-color: #e6495e;
                    color: #fff;
                    transition: all 0.2s linear;
                }
            }
        }
    }
    .describe-cv {
        width: 66.66666%;
        .curriculum-vitae {
            font-family: "Source Sans Pro", sans-serif;
            font-size: 30px;
            font-weight: 600;
            color: #0c4892;
            position: relative;
            margin-bottom: 30px;
            padding-bottom: 10px;

            &::after {
                content: "";
                position: absolute;
                bottom: 0;
                left: 0;
                height: 2px;
                width: 100px;
                border-radius: 999px;
                background-color: #e6495e;
            }
        }
        .description-doctor {
        }
    }
}
