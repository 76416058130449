/*
 * General styles
 */
 body, html {
    background-image: "#fff";
    background-repeat: no-repeat;
    background-size: cover;
    font-family: $fontmain;
    font-size: $base-size - 2px;
    height: 100vh;
}

.btn {
    padding: 0;
    border: none;
    cursor: pointer;
    font-weight: 700;
    height: 30px;
    line-height: 30px;
    -moz-user-select: none;
    -webkit-user-select: none;
    user-select: none;
    i {
      font-size: $base-size - 3px;
    }
}

.btn-edit, 
.btn-add,
.btn-delete,
.btn-assign {
  background-color: $colormain;
  color: $common-text-color;
  &:hover {
    color: $common-text-color;
    background-color: darken($colormain, 5);
  }
}

#password:invalid {
  font-family: $fontmain; // Fixed TH hien thi placeholder cho EDGE
}
#password:-webkit-input-placeholder {
  font-family: $fontmain; // CHROME 1
}
#password::-webkit-input-placeholder {
  font-family: $fontmain; //CHROME 2   EDGE 1
}
#password:-moz-placeholder {
  font-family: $fontmain; // Firefox 4-18
}
#password::-moz-placeholder {
  font-family: $fontmain; // Firefox 19+
}
#password:-ms-input-placeholder {
  font-family: $fontmain; // IE 10-11
}
#password::-ms-input-placeholder {
  font-family: $fontmain; // EDGE 2
}
#password::placeholder { 
  font-family: $fontmain;
}

.content-container {
  .title {
    text-align: center;
    text-transform: uppercase;
    font-weight: bold;
    font-size: $base-size + 4px;
    margin-top: 15px;
    color: $colormain;
  }
  .content {
    margin: 15px 30px;
    box-shadow: 2px 2px 4px 0 $box-shadow-color;
    -webkit-box-shadow: 2px 2px 4px 0 $box-shadow-color;
  }
}

.modal {
  .modal-dialog {
    .modal-content {
      .modal-header {
        padding: 0 10px;
        height: 35px;
        background-color: $colormain;
        color: $common-text-color;
        .modal-title {
          line-height: 35px;
          font-weight: 500;
          font-size: $base-size + 1px;
        }
        .btn-close {
          position: absolute;
          color: $common-text-color;
          i {
            line-height: 35px;
            font-size: $base-size + 2px;
          }
        }
      }
    }
  }
}