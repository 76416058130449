.detail-doctor {
    display: flex;
    .img-doctor {
       
        padding: 10px;
        cursor: pointer;
        .img {
            height: 200px;
            width: 200px;
            background-position: center;
            background-size: cover;
            border-radius: 50%;
        }
    }
    .info-doctor {
        margin-left: 20px;
        padding-top: 30px;
        .name-doctor {
            font-family: 'Source Sans Pro', sans-serif;
            font-size: 30px;
            font-weight: 600;
            color: #0c4892;
            position: relative;
            margin-bottom: 10px;
            padding-bottom: 10px;

            &::after {
                content: '';
                position: absolute;
                bottom: 0;
                left: 0;
                height: 2px;
                width: 100px;
                border-radius: 999px;
                background-color: #e6495e;
            }
        }
        .description-doctor {
            font-family: 'Trocchi', serif; 
            font-size: 16px;
            width: 500px;
            text-align: justify;

            color: #707573;
        }
    }
}
