header {
    background: url("../../../src/assets/images/header-bg.jpg") no-repeat;
    background-size: contain;
    width: 100%;
    z-index: 10;
    background-size: cover;
}
.header--top {
    display: flex;
    justify-content: flex-end !important;
    height: 55px;
    align-items: center;
}
.row {
    margin-right: -15px;
    margin-left: -15px;
}

.text-center {
    text-align: center;
}
.justify-content-end {
    justify-content: flex-end;
}
.align-items-center {
    -ms-flex-align: center !important;
    align-items: center !important;
}
.list-unstyled {
    display: flex;
}
.list-unstyled {
    padding-left: 0;
    list-style: none;
}
ol,
ul {
    list-style: none;
}
ul,
li {
    margin: 0px;
    padding: 0px;
}
.header--top .top--list li {
    position: relative;
    padding: 5px 5px;
    display: inline-block;
    color: #111;
    opacity: 0.65;
    font-size: 13px;
}
.list-unstyled > li {
    padding-left: 0;
    list-style: none !important;
    margin-bottom: 0px;
    display: flex;
}
ul li.social--facebook .icon-top-top-header {
    background: #4360aa;
    transition: all 0.2s linear;
    height: 28px;
    width: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    color: #fff;
    margin: 0 2px;
    line-height: 30px;
}
ul li.social--zalo .icon-top-top-header {
    background: #e1306c;
    transition: all 0.2s linear;
    height: 28px;
    width: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    color: #fff;
    margin: 0 2px;
    line-height: 30px;
}
ul li.social--facebook:hover .icon-top-top-header {
    background: #223975;
    transition: all 0.2s linear;
    transform: translateY(-4px);
}
ul li.social--zalo:hover .icon-top-top-header {
    background: #cc1f59;
    transition: all 0.2s linear;
    transform: translateY(-4px);
}
.list-unstyled > li {
    padding-left: 0;
    list-style: none !important;
    margin-bottom: 0px;
    display: flex;
}
.social--facebook,
.social--youtube,
.social--zalo {
    opacity: 1 !important;
}
.nav-top-menu {
    position: sticky;
    top: 0;
    z-index: 1000;
}
.navbar {
    position: relative;
    z-index: 9;
    margin-bottom: 0px;
    border: none;
    background-color: #36048e;
    position: sticky;
    -webkit-box-shadow: 0 0 6px 2px rgba(0, 0, 0, 0.1);
    box-shadow: 0 0 6px 2px rgba(0, 0, 0, 0.1);
}
.nav-right {
    display: flex;
    flex-direction: row;
    align-items: center;
    float: right;
}
.nav-right li {
    padding: 5px;
    border-right: 1px solid rgba(255, 255, 255, 0.2);
    padding: 5px 10px;
    color: white;
    cursor: pointer;
}
.btn-logout i {
    color: white;
}
.changeLanguage {
    position: relative;
    display: block;
    padding: 10px 15px;
    line-height: 20px;
}
.changeLanguage {
    margin-left: 20px;
    .changeLanguageVI {
        margin-right: 10px;
        font-weight: 600;
        &.active {
            color: #ffa6fe;
        }
    }
    .changeLanguageEN {
        font-weight: 600;
        &.active {
            color: rgb(0, 195, 255);
        }
    }
}

.logomenu {
    width: 120px;
    top: -55px;
    /* left: 0; */
    position: absolute;
    z-index: 2;
    /* background: #fff; */
    height: 105px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 0.125rem 0.5rem #00000014 !important;
    transition: 0.3s all ease;
    background-color: white;
    border-radius: 4px;
    cursor: pointer;
}
.logomenu img {
    transition: 0.3s all ease-in-out;
    height: 100%;
    border-radius: 8px;
}
img {
    vertical-align: middle;
}
.navbar-inverse {
    padding: 0;
}

.navbar-inverse .navbar-collapse,
.navbar-inverse .navbar-form {
    border-color: #101010;
}
.navbar-inverse .navbar-nav {
    display: flex;
    flex-direction: row;
}
.navbar-inverse .navbar-nav > li {
    border-right: 1px solid rgba(255, 255, 255, 0.2);
    padding: 5px;
    cursor: pointer;
}
.navbar-inverse .navbar-nav > li:hover {
    background-color: #cb3a3f;
}
.nav > li {
    position: relative;
    display: block;
}
.navbar-inverse .navbar-nav > li > .content-top-header {
    color: white;
}
.first-li-header {
    margin-left: 100px;
}
.navbar-nav > li > .content-top-header {
    position: relative;
    display: block;
    padding: 10px 15px;
    line-height: 20px;
}
.navbar > .container {
    flex-wrap: nowrap !important;
}

@media (min-width: 768px) {
    .navbar-collapse.collapse {
        display: block !important;
        height: auto !important;
        padding-bottom: 0;
        overflow: visible !important;
    }
    .navbar-nav > li {
        float: left;
    }
}
@media (min-width: 768px) {
    .navbar-left {
        float: left !important;
    }
}
.header-bottom {
    position: sticky;
    top: 50px;
    z-index: 999;
    background: #fff;
}
.align-items-center {
    -ms-flex-align: center !important;
    align-items: center !important;
}
.header-bottom .information-item {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    font-size: 14px;
    color: #000;
    padding: 10px 5px;
    transition: 0.3s all ease;
}
.header-bottom .information-item .hotline {
    padding-left: 10px;
    margin-left: 10px;
    position: relative;
}
.header-bottom h6 {
    font-weight: 700;
    font-size: 14px;
    color: #0a4996;
    text-transform: uppercase;
    margin-top: 0;
    margin-bottom: 0;
    padding-left: 0;
    transition: 0.3s all ease;
}

.header-bottom .information-item .hotline:before {
    position: absolute;
    content: "";
    display: block;
    height: 10px;
    width: 1px;
    background: #a0a0a0;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
}
.hotline span {
    color: #337ab7;
    margin-left: 3px;
}
.sticky-header-bottom h6 {
    transform: translateX(130px);
}
.sticky-header-bottom {
    box-shadow: 0 -4px 15px 0px #0000009c;
}
.sticky-header .logomenu {
    top: 0;
    height: 89px;
}
.sticky-header-bottom .information-item {
    font-size: 13px !important;
}
.name-user-header {
    margin: 0 10px;
}
