table {
    font-family: arial, sans-serif;
    border-collapse: collapse;
    width: 100%;
    width: 1100px;
    margin-left: 240px;
}

td,
th {
    border: 1px solid #dddddd;
    text-align: left;
    padding: 8px;
}

tr:nth-child(even) {
    background-color: #dddddd;
}
button {
    border: none;
    outline: none;
    margin: 0 10px;
}
