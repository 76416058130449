.backToTop {
    height: 35px;
    line-height: 35px;
    width: 35px;
    font-size: 30px;
    z-index: 998;
    background-color: #7af8e7;
    position: fixed;
    right: 30px;
    bottom: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 33%;
    cursor: pointer;
    transition: all 0.2s linear;
}
.noneArrow {
    display: none;
}
.backToTop:hover {
    box-shadow: 0 1px 1px 0px;
    background-color: #50f1dc;

    transition: all 0.2s linear;
}
.backToTop:hover i {
    color: #272626;

    transition: all 0.2s linear;
}
