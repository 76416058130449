.space-top {
    height: 78px;
}
.description-top {
    font-family: Montserrat, sans-serif !important;
    background: center center no-repeat;
    background-size: cover;

    .overlay {
        background-image: linear-gradient(rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 1));
        padding-top: 20px;
        .container {
            .content-top {
                .main-content-top {
                    transition: all 0.3s ease;

                    overflow: hidden;
                    .title-content-top {
                        font-size: 18px;
                        font-weight: 700;
                    }
                }
                .see-more {
                    padding: 15px 0;
                    span {
                        font-size: 17px;
                        color: rgb(13, 166, 226);
                        font-weight: 600;
                        cursor: pointer;
                        &:hover {
                            text-decoration: underline;
                        }
                    }
                }
            }
        }
    }
}

@keyframes openSeeMore {
    0% {
        height: 200px;
    }
    50% {
        height: calc(200px + 50px);
    }
    75% {
        height: calc(200px + 200px);
    }
    100% {
        height: auto;
    }
}
@keyframes closeSeeMore {
    0% {
        height: auto;
    }
    25% {
        height: calc(500px - 100px);
    }
    75% {
        height: calc(500px - 200px);
    }
    100% {
        height: 200px;
    }
}

.main-container {
    
    .container {
        .select-location {
            padding-top: 20px;
            margin-bottom: 20px;
            .selected {
                padding: 10px;
                outline: none;
            }
        }

        .infomation-doctor {
            display: flex;
            width: 1140px;
            // height: 493px;
            padding: 10px;
            margin-bottom: 30px;
            border: 1px solid seagreen;
            border-radius: 8px;
            box-shadow: 0 0 24px -12px black;
            .infomation-doctor-right {
                width: 100%;
                height: 100%;
                .detail-doctor {
                    .info-doctor {
                        .name-doctor {
                            cursor: pointer;
                        }
                        .description-doctor {
                            width: 90% !important;
                        }
                    }
                }
            }
            // .infomation-doctor-left {
            //     width: 50%;
            //     height: 100%;
            //     margin-left: 10px;
            //     .info__schedules {
            //         .appointment-left {
            //             .btn-data {
            //                 width: 135px !important;
            //             }
            //         }
            //     }
            // }
        }
    }
}
// doctor info

.space-footer {
    height: 500px;
}
