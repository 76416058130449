// .container-main-banner {
//     width: 100vw;
//     overflow: hidden;
//     .main-banner {
//         display: flex;
//         flex-direction: row;
//         width: 200vw;
//         .banner-area {
//             width: 100%;
//             background: url("../../assets/images/banner01.png") center no-repeat;
//             height: 507px;
//             background-size: cover;
//             position: relative;
//             animation: banner2-active 0.3s linear;
//             .arrow-next {
//                 height: 50px;
//                 width: 50px;
//                 background-color: rgba(255, 255, 255, 0.7);
//                 color: rgba(0, 0, 0, 0.6);
//                 border-radius: 50%;
//                 font-size: 30px;
//                 position: absolute;
//                 top: 50%;
//                 right: 22px;
//                 text-align: center;
//                 cursor: pointer;
//                 line-height: 51px;
//                 z-index: 20;
//                 transition: all 0.1s linear;
//                 &:hover {
//                     background-color: rgba(255, 255, 255, 1);
//                     color: rgba(104, 4, 77, 0.6);
//                     transition: all 0.3s linear;
//                 }
//             }
//             .banner--overlay {
//                 position: absolute;
//                 top: 0;
//                 bottom: 0;
//                 right: 0;
//                 left: 0;
//                 background-color: rgba(0, 0, 0, 0.05);
//                 .banner-content {
//                     list-style: none;
//                     display: flex;
//                     flex-direction: column;
//                     text-align: center;
//                     padding: 0;
//                     height: 100%;
//                     width: 100%;
//                     position: absolute;
//                     z-index: 5;
//                     .banner-item-1 {
//                         height: 25%;
//                         text-transform: uppercase;
//                         font-size: 30px;
//                         letter-spacing: 2px;
//                         display: flex;
//                         flex-direction: column;
//                         justify-content: end;
//                         .title-1-item-1 {
//                             font-size: 24px;
//                             font-weight: 600;
//                             color: rgb(236, 93, 112);
//                             font-family: "Courier New", Courier, monospace;
//                         }
//                         .title-2-item-2 {
//                             font-size: 32px;
//                             font-weight: 700;
//                             color: rgb(81, 221, 212);
//                             font-family: "Courier New", Courier, monospace;
//                             text-shadow: 1px 1px rgb(0, 0, 0);
//                         }
//                     }
//                     .banner-item-2 {
//                         height: 25%;

//                         .search-btn {
//                             display: flex;
//                             align-items: center;

//                             background-color: #f7d800;
//                             border-radius: 999px;
//                             margin: 10px auto 0;
//                             width: 456px;
//                             height: 50px;
//                             .search-icon {
//                                 font-size: 18px;
//                                 height: 50px;
//                                 width: 50px;
//                                 line-height: 50px;
//                             }
//                             .input-search {
//                                 input {
//                                     width: 350px;
//                                     background-color: unset;
//                                     border: none;
//                                     outline: none;
//                                 }
//                             }
//                         }
//                     }
//                     .banner-item-3 {
//                         height: 25%;
//                         .banner-download {
//                             width: 100%;
//                             height: 100%;
//                             display: flex;
//                             justify-content: center;
//                             align-items: center;
//                             .android {
//                                 background: url("https://bookingcare.vn/assets/icon/google-play-badge.svg") no-repeat
//                                     center;
//                                 background-size: cover;
//                                 width: 108px;
//                                 height: 32px;
//                                 margin-right: 20px;
//                                 cursor: pointer;
//                             }
//                             .ios {
//                                 background: url("https://bookingcare.vn/assets/icon/app-store-badge-black.svg")
//                                     no-repeat center;
//                                 background-size: cover;
//                                 width: 108px;
//                                 height: 32px;
//                                 cursor: pointer;
//                             }
//                         }
//                     }
//                     .banner-item-4 {
//                         height: 25%;
//                         background-image: linear-gradient(transparent, white);
//                         .list-banner-item-4 {
//                             list-style: none;
//                             display: flex;
//                             justify-content: center;
//                             padding-left: 0;
//                             .list--item1 {
//                                 min-width: 150px;
//                                 font-size: 16px;
//                                 margin: 25px 5px;
//                                 .banner4-icon1 {
//                                     background-image: url("https://bookingcare.vn/assets/anh/kham_chuyenkhoa.png");
//                                     background-repeat: no-repeat;
//                                     background-position: center;
//                                     width: 50px;
//                                     height: 50px;
//                                     margin: 2px auto;
//                                     background-size: 32px;
//                                     background-color: white;
//                                     border-radius: 50%;
//                                 }
//                                 .banner4-title1 {
//                                     font-size: 16px;
//                                     font-weight: 600;
//                                 }
//                                 .banner4-title1:hover {
//                                     color: #45c3d2;
//                                 }
//                             }
//                             .list--item2 {
//                                 min-width: 150px;
//                                 font-size: 16px;
//                                 margin: 25px 5px;
//                                 .banner4-icon2 {
//                                     background-image: url("https://bookingcare.vn/assets/anh/kham_tuxa.png");
//                                     background-repeat: no-repeat;
//                                     background-position: center;
//                                     width: 50px;
//                                     height: 50px;
//                                     margin: 2px auto;
//                                     background-size: 32px;
//                                     background-color: white;
//                                     border-radius: 50%;
//                                 }
//                                 .banner4-title2 {
//                                     font-size: 16px;
//                                     font-weight: 600;
//                                 }
//                                 .banner4-title2:hover {
//                                     color: #45c3d2;
//                                 }
//                             }
//                             .list--item3 {
//                                 min-width: 150px;
//                                 font-size: 16px;
//                                 margin: 25px 5px;
//                                 .banner4-icon3 {
//                                     background-image: url("https://bookingcare.vn/assets/anh/kham_tongquat.png");
//                                     background-repeat: no-repeat;
//                                     background-position: center;
//                                     width: 50px;
//                                     height: 50px;
//                                     margin: 2px auto;
//                                     background-size: 32px;
//                                     background-color: white;
//                                     border-radius: 50%;
//                                 }
//                                 .banner4-title3 {
//                                     font-size: 16px;
//                                     font-weight: 600;
//                                 }
//                                 .banner4-title3:hover {
//                                     color: #45c3d2;
//                                 }
//                             }
//                             .list--item4 {
//                                 min-width: 150px;
//                                 font-size: 16px;
//                                 margin: 25px 5px;
//                                 .banner4-icon4 {
//                                     background-image: url("https://bookingcare.vn/assets/anh/dichvu_xetnghiem.png");
//                                     background-repeat: no-repeat;
//                                     background-position: center;
//                                     width: 50px;
//                                     height: 50px;
//                                     margin: 2px auto;
//                                     background-size: 32px;
//                                     background-color: white;
//                                     border-radius: 50%;
//                                 }
//                                 .banner4-title4 {
//                                     font-size: 16px;
//                                     font-weight: 600;
//                                 }
//                                 .banner4-title4:hover {
//                                     color: #45c3d2;
//                                 }
//                             }
//                             .list--item5 {
//                                 min-width: 150px;
//                                 font-size: 16px;
//                                 margin: 25px 5px;
//                                 .banner4-icon5 {
//                                     background-image: url("https://bookingcare.vn/assets/anh/kham_nhakhoa.png");
//                                     background-repeat: no-repeat;
//                                     background-position: center;
//                                     width: 50px;
//                                     height: 50px;
//                                     margin: 2px auto;
//                                     background-size: 32px;
//                                     background-color: white;
//                                     border-radius: 50%;
//                                 }
//                                 .banner4-title5 {
//                                     font-size: 16px;
//                                     font-weight: 600;
//                                 }
//                                 .banner4-title5:hover {
//                                     color: #45c3d2;
//                                 }
//                             }
//                             .list--item6 {
//                                 min-width: 150px;
//                                 font-size: 16px;
//                                 margin: 25px 5px;
//                                 .banner4-icon6 {
//                                     background-image: url("https://bookingcare.vn/assets/anh/suckhoe_tinhthan.png");
//                                     background-repeat: no-repeat;
//                                     background-position: center;
//                                     width: 50px;
//                                     height: 50px;
//                                     margin: 2px auto;
//                                     background-size: 32px;
//                                     background-color: white;
//                                     border-radius: 50%;
//                                 }
//                                 .banner4-title6 {
//                                     font-size: 16px;
//                                     font-weight: 600;
//                                 }
//                                 .banner4-title6:hover {
//                                     color: #45c3d2;
//                                 }
//                             }
//                         }
//                     }
//                 }
//             }
//         }
//         .banner-area-2 {
//             width: 100%;
//             background: url("../../assets/images/image-live-speaking.png") center no-repeat;
//             height: 507px;
//             background-size: cover;
//             position: relative;
//             animation: banner2-active 0.3s linear;
//             // transform: translateX(100%);
//             .arrow-prev {
//                 height: 50px;
//                 width: 50px;
//                 background-color: rgba(255, 255, 255, 0.7);
//                 color: rgba(0, 0, 0, 0.6);
//                 border-radius: 50%;
//                 font-size: 30px;
//                 position: absolute;
//                 top: 50%;
//                 left: 22px;
//                 text-align: center;
//                 cursor: pointer;
//                 line-height: 51px;
//                 z-index: 20;
//                 transition: all 0.1s linear;
//                 &:hover {
//                     background-color: rgba(255, 255, 255, 1);
//                     color: rgba(104, 4, 77, 0.6);
//                     transition: all 0.3s linear;
//                 }
//             }
//             .banner--overlay {
//                 position: absolute;
//                 top: 0;
//                 bottom: 0;
//                 right: 0;
//                 left: 0;
//                 background-color: rgba(0, 0, 0, 0.2);
//                 .banner-content {
//                     list-style: none;
//                     display: flex;
//                     flex-direction: column;
//                     text-align: center;
//                     padding: 0;
//                     height: 100%;
//                     width: 100%;
//                     position: absolute;
//                     z-index: 5;
//                     .banner-item-1 {
//                         height: 25%;
//                         text-transform: uppercase;
//                         font-size: 30px;
//                         letter-spacing: 2px;
//                         display: flex;
//                         flex-direction: column;
//                         justify-content: end;
//                         .title-1-item-1 {
//                             font-size: 24px;
//                             font-weight: 600;
//                             color: rgb(252, 185, 194);
//                             font-family: "Courier New", Courier, monospace;
//                         }
//                         .title-2-item-2 {
//                             font-size: 32px;
//                             font-weight: 700;
//                             color: rgb(212, 245, 243);
//                             font-family: "Courier New", Courier, monospace;
//                             text-shadow: 1px 1px rgb(0, 0, 0);
//                         }
//                     }
//                     .banner-item-2 {
//                         height: 25%;

//                         .search-btn {
//                             display: flex;
//                             align-items: center;

//                             background-color: #f7d800;
//                             border-radius: 999px;
//                             margin: 10px auto 0;
//                             width: 456px;
//                             height: 50px;
//                             .search-icon {
//                                 font-size: 18px;
//                                 height: 50px;
//                                 width: 50px;
//                                 line-height: 50px;
//                             }
//                             .input-search {
//                                 input {
//                                     width: 350px;
//                                     background-color: unset;
//                                     border: none;
//                                     outline: none;
//                                 }
//                             }
//                         }
//                     }
//                     .banner-item-3 {
//                         height: 25%;
//                         .banner-download {
//                             width: 100%;
//                             height: 100%;
//                             display: flex;
//                             justify-content: center;
//                             align-items: center;
//                             .android {
//                                 background: url("https://bookingcare.vn/assets/icon/google-play-badge.svg") no-repeat
//                                     center;
//                                 background-size: cover;
//                                 width: 108px;
//                                 height: 32px;
//                                 margin-right: 20px;
//                                 cursor: pointer;
//                             }
//                             .ios {
//                                 background: url("https://bookingcare.vn/assets/icon/app-store-badge-black.svg")
//                                     no-repeat center;
//                                 background-size: cover;
//                                 width: 108px;
//                                 height: 32px;
//                                 cursor: pointer;
//                             }
//                         }
//                     }
//                     .banner-item-4 {
//                         height: 25%;
//                         background-image: linear-gradient(transparent, white);
//                         .list-banner-item-4 {
//                             list-style: none;
//                             display: flex;
//                             justify-content: center;
//                             padding-left: 0;
//                             .list--item1 {
//                                 min-width: 150px;
//                                 font-size: 16px;
//                                 margin: 25px 5px;
//                                 .banner4-icon1 {
//                                     background-image: url("https://bookingcare.vn/assets/anh/kham_chuyenkhoa.png");
//                                     background-repeat: no-repeat;
//                                     background-position: center;
//                                     width: 50px;
//                                     height: 50px;
//                                     margin: 2px auto;
//                                     background-size: 32px;
//                                     background-color: white;
//                                     border-radius: 50%;
//                                 }
//                                 .banner4-title1 {
//                                     font-size: 16px;
//                                     font-weight: 600;
//                                 }
//                                 .banner4-title1:hover {
//                                     color: #45c3d2;
//                                 }
//                             }
//                             .list--item2 {
//                                 min-width: 150px;
//                                 font-size: 16px;
//                                 margin: 25px 5px;
//                                 .banner4-icon2 {
//                                     background-image: url("https://bookingcare.vn/assets/anh/kham_tuxa.png");
//                                     background-repeat: no-repeat;
//                                     background-position: center;
//                                     width: 50px;
//                                     height: 50px;
//                                     margin: 2px auto;
//                                     background-size: 32px;
//                                     background-color: white;
//                                     border-radius: 50%;
//                                 }
//                                 .banner4-title2 {
//                                     font-size: 16px;
//                                     font-weight: 600;
//                                 }
//                                 .banner4-title2:hover {
//                                     color: #45c3d2;
//                                 }
//                             }
//                             .list--item3 {
//                                 min-width: 150px;
//                                 font-size: 16px;
//                                 margin: 25px 5px;
//                                 .banner4-icon3 {
//                                     background-image: url("https://bookingcare.vn/assets/anh/kham_tongquat.png");
//                                     background-repeat: no-repeat;
//                                     background-position: center;
//                                     width: 50px;
//                                     height: 50px;
//                                     margin: 2px auto;
//                                     background-size: 32px;
//                                     background-color: white;
//                                     border-radius: 50%;
//                                 }
//                                 .banner4-title3 {
//                                     font-size: 16px;
//                                     font-weight: 600;
//                                 }
//                                 .banner4-title3:hover {
//                                     color: #45c3d2;
//                                 }
//                             }
//                             .list--item4 {
//                                 min-width: 150px;
//                                 font-size: 16px;
//                                 margin: 25px 5px;
//                                 .banner4-icon4 {
//                                     background-image: url("https://bookingcare.vn/assets/anh/dichvu_xetnghiem.png");
//                                     background-repeat: no-repeat;
//                                     background-position: center;
//                                     width: 50px;
//                                     height: 50px;
//                                     margin: 2px auto;
//                                     background-size: 32px;
//                                     background-color: white;
//                                     border-radius: 50%;
//                                 }
//                                 .banner4-title4 {
//                                     font-size: 16px;
//                                     font-weight: 600;
//                                 }
//                                 .banner4-title4:hover {
//                                     color: #45c3d2;
//                                 }
//                             }
//                             .list--item5 {
//                                 min-width: 150px;
//                                 font-size: 16px;
//                                 margin: 25px 5px;
//                                 .banner4-icon5 {
//                                     background-image: url("https://bookingcare.vn/assets/anh/kham_nhakhoa.png");
//                                     background-repeat: no-repeat;
//                                     background-position: center;
//                                     width: 50px;
//                                     height: 50px;
//                                     margin: 2px auto;
//                                     background-size: 32px;
//                                     background-color: white;
//                                     border-radius: 50%;
//                                 }
//                                 .banner4-title5 {
//                                     font-size: 16px;
//                                     font-weight: 600;
//                                 }
//                                 .banner4-title5:hover {
//                                     color: #45c3d2;
//                                 }
//                             }
//                             .list--item6 {
//                                 min-width: 150px;
//                                 font-size: 16px;
//                                 margin: 25px 5px;
//                                 .banner4-icon6 {
//                                     background-image: url("https://bookingcare.vn/assets/anh/suckhoe_tinhthan.png");
//                                     background-repeat: no-repeat;
//                                     background-position: center;
//                                     width: 50px;
//                                     height: 50px;
//                                     margin: 2px auto;
//                                     background-size: 32px;
//                                     background-color: white;
//                                     border-radius: 50%;
//                                 }
//                                 .banner4-title6 {
//                                     font-size: 16px;
//                                     font-weight: 600;
//                                 }
//                                 .banner4-title6:hover {
//                                     color: #45c3d2;
//                                 }
//                             }
//                         }
//                     }
//                 }
//             }
//         }
//     }
// }

@keyframes banner1-active {
    0% {
        transform: translateX(-100%);
    }
    100% {
        transform: translateX(0);
    }
}
@keyframes banner2-active {
    0% {
        transform: translateX(0);
    }
    100% {
        transform: translateX(-100%);
    }
}

.option-learning {
    padding: 0 0 10px 0;
    background-color: #ffffffb8;
    .header-option {
        height: 290px;
        width: 100%;
        background: rgb(29, 91, 164);
        display: flex;
        flex-direction: column;
        justify-content: center;
        .header-option-1 {
            color: #fa687c;
            font-family: Arial, Helvetica, sans-serif;
            font-size: 22px;
            text-align: center;
        }
        .header-option-2 {
            color: #fff;
            font-family: Arial, Helvetica, sans-serif;
            font-size: 28px;
            font-weight: 600;
            text-align: center;
        }
    }
    .container-option {
        width: 1173px;
        background-color: #fff;
        margin: -40px auto 0;
        padding-bottom: 25px;
        box-shadow: 0 20px 10px 2px rgba(0, 0, 0, 0.4);

        .content-option {
            display: flex;
            width: 100%;
            padding: 14px;
            .content-option-item--1 {
                margin: 0 5px;
                width: 33.3333%;

                padding: 0 20px;
                text-align: center;
                .iamge-option-1 {
                    background-image: url("../../assets/images/ielts-removebg-preview.png");
                    height: 200px;
                    width: 100%;
                    background-size: contain;
                    background-position: center;
                    background-repeat: no-repeat;
                }
                .name-option-1 {
                    font-size: 1.25rem;
                    color: #3370bc;
                }
                .describe-option-1 {
                    font-size: 1.1rem;
                }
                .read-option-1 {
                    width: 100%;
                    cursor: pointer;
                    .read-more-btn {
                        width: 100%;
                        div {
                            width: 110px;
                            padding: 10px;
                            margin: 10px auto;
                            border: 1px solid aqua;
                            transition: all 0.2s linear;
                            border-radius: 12px;

                            &:hover {
                                background-color: rgb(19, 207, 144);
                                color: white;
                                transition: all 0.2s linear;
                                font-weight: 600;
                            }
                        }
                    }
                }
            }
            .content-option-item--2 {
                margin: 0 5px;
                width: 33.3333%;

                padding: 0 20px;
                text-align: center;
                .iamge-option-2 {
                    background-image: url("../../assets/images/ielts-removebg-preview.png");
                    height: 200px;
                    width: 100%;
                    background-size: contain;
                    background-position: center;
                    background-repeat: no-repeat;
                }
                .name-option-2 {
                    font-size: 1.25rem;
                    color: #3370bc;
                }
                .describe-option-2 {
                    font-size: 1.1rem;
                }
                .read-option-2 {
                    width: 100%;
                    cursor: pointer;
                    .read-more-btn {
                        width: 100%;
                        div {
                            width: 110px;
                            padding: 10px;
                            margin: 10px auto;
                            border: 1px solid aqua;
                            transition: all 0.2s linear;
                            border-radius: 12px;

                            &:hover {
                                background-color: rgb(19, 207, 144);
                                color: white;
                                transition: all 0.2s linear;
                                font-weight: 600;
                            }
                        }
                    }
                }
            }
            .content-option-item--3 {
                margin: 0 5px;
                width: 33.3333%;

                padding: 0 20px;
                text-align: center;
                .iamge-option-3 {
                    background-image: url("../../assets/images/ielts-removebg-preview.png");
                    height: 200px;
                    width: 100%;
                    background-size: contain;
                    background-position: center;
                    background-repeat: no-repeat;
                }
                .name-option-3 {
                    font-size: 1.25rem;
                    color: #3370bc;
                }
                .describe-option-3 {
                    font-size: 1.1rem;
                }
                .read-option-3 {
                    width: 100%;
                    cursor: pointer;
                    .read-more-btn {
                        width: 100%;
                        div {
                            width: 110px;
                            padding: 10px;
                            margin: 10px auto;
                            border: 1px solid aqua;
                            transition: all 0.2s linear;
                            border-radius: 12px;

                            &:hover {
                                background-color: rgb(19, 207, 144);
                                color: white;
                                transition: all 0.2s linear;
                                font-weight: 600;
                            }
                        }
                    }
                }
            }
        }
        .see-all-courses {
            width: 100%;
            text-align: center;
            margin-top: 35px;
            .content-see-all-course {
                width: 240px;
                height: 46px;
                cursor: pointer;
                line-height: 46px;
                font-size: 16px;
                text-transform: uppercase;
                background-color: #e6495e;
                color: #fff;
                margin: 0 auto;
                border-radius: 999px;
                transition: all 0.2s linear;

                &:hover {
                    background-color: #fff;
                    border: 2px solid #e6495e;
                    color: #e6495e;
                    transition: all 0.2s linear;
                }
            }
        }
    }
}

.container-main-banner {
    width: 100vw;
    overflow: hidden;
    .main-banner {
        position: relative;
        width: 100%;
        background: url("../../assets/images/banner01.png") center no-repeat;

        background-size: cover;
    }
}
.content-banner-1 {
    height: 625px;
}
.text-banner-left {
    font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
    font-size: 48px;
    font-weight: 800;
    line-height: 1em;
    margin-bottom: 20px;
}
@media (max-width: 1680px) and (min-width: 1200px) {
    .feature-area {
        margin-top: -110px;
    }
}
.feature-area .container-fluid {
    padding-left: 50px;
}
.container-fluid {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}
.feature-item {
    margin: 30px 0;
    border-right: 1px solid rgba(130, 139, 178, 0.2);
    padding-right: 20px;
}
.feature-item i {
    font-size: 30px;
    color: #828bb2;
}
.feature-item h4 {
    font-size: 18px;
    margin-bottom: 20px;
    font-weight: 700;
}
.feature-item p {
    margin-bottom: 0;
    color: #828bb2;
}
.ml-20 {
    margin-left: 20px;
}