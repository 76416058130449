.description-top {
    font-family: Montserrat, sans-serif !important;
    background: center center no-repeat;
    background-size: cover;

    .overlay {
        background-image: linear-gradient(rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 1));
        padding-top: 20px;
        .container {
            .content-top {
                .main-content-top {
                    transition: all 0.3s ease;

                    overflow: hidden;
                    .title-content-top {
                        font-size: 18px;
                        font-weight: 700;
                    }
                }
                .see-more {
                    padding: 15px 0;
                    span {
                        font-size: 17px;
                        color: rgb(13, 166, 226);
                        font-weight: 600;
                        cursor: pointer;
                        &:hover {
                            text-decoration: underline;
                        }
                    }
                }
            }
        }
    }
}

@keyframes openSeeMore {
    0% {
        height: 200px;
    }
    50% {
        height: calc(200px + 50px);
    }
    75% {
        height: calc(200px + 200px);
    }
    100% {
        height: auto;
    }
}
@keyframes closeSeeMore {
    0% {
        height: auto;
    }
    25% {
        height: calc(500px - 100px);
    }
    75% {
        height: calc(500px - 200px);
    }
    100% {
        height: 200px;
    }
}

.main-container {
    .container {
        .select-location {
            padding-top: 20px;
            margin-bottom: 20px;
            .selected {
                padding: 10px;
                outline: none;
            }
        }

        .infomation-doctor {
            display: flex;
            width: 1000px;
            // height: 493px;
            padding: 10px;
            margin-bottom: 30px;
            border: 1px solid seagreen;
            border-radius: 8px;
            box-shadow: 0 0 24px -12px black;
            .infomation-doctor-right {
                width: 70%;
                height: 100%;
                .detail-doctor {
                    .info-doctor {
                        .name-doctor {
                            cursor: pointer;
                        }
                        .description-doctor {
                            width: 90% !important;
                        }
                    }
                }
            }
            .infomation-doctor-left {
                width: 30%;
                height: 100%;
                margin-left: 10px;

                .info__schedules {
                    .appointment-left {
                        .btn-data {
                            width: 135px !important;
                        }
                    }
                }
                .online-consultant {
                    height: 81px;
                    background-color: white;
                    position: absolute;
                    width: 176px;
                    .call-consultant {
                        text-align: center;
                        margin: 14px auto 0;
                        width: 114px;
                    }
                }
            }
            .consultant-offline {
                width: 70%;
                span {
                    font-size: 20px;
                    font-weight: 600;
                    color: #fc122e;
                }
            }
        }
    }
    .anwsering-btn {
        border-radius: 12px;
        width: 270px;
        height: 270px;
        position: fixed;
        z-index: 1000;
        top: 30%;
        right: 1%;
        padding: 5px;
        overflow: hidden;

        .border-calling {
            width: 270px;
            height: 270px;
            position: absolute;
            animation: rotateBorderCalling 8s linear infinite;

            .border-call-1 {
                display: block;
                width: 100%;
                height: 100%;
                position: relative;
                transform: translate(-50%, -50%);
                background-color: #ff909f;

                &::after {
                    content: "";
                    display: block;
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    left: 100%;
                    background-color: #d84680;
                }
            }
            .border-call-2 {
                display: block;
                width: 100%;
                height: 100%;
                position: relative;
                transform: translate(-50%, -50%);
                background-color: #983a78;

                &::after {
                    content: "";
                    display: block;
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    left: 100%;
                    background-color: #fd8e9b;
                }
            }
        }

        .notification-calling {
            position: absolute;
            z-index: 1200;
            background-color: #698df4;
            width: 96%;
            height: 96%;
            padding: 30px 10px 14px;
            border-radius: 12px;

            .name-calling {
                .img-caller {
                    margin: 0 auto;
                    background-image: url("../../../assets/images/start-call.png");
                    border-radius: 50%;
                    height: 100px;
                    width: 100px;
                    background-size: contain;
                    transition: animation 5s linear;
                    animation: calling 1s linear infinite;
                }
                .name-user-caller {
                    text-align: center;
                    margin-top: 25px;
                    font-size: 18px;
                    color: #77edff;
                    span {
                        font-size: 25px;
                        font-weight: 600;
                        margin-right: 6px;
                        color: #77edff;
                    }
                }
            }

            .call-consultant {
                text-align: center;
                margin: 14px auto 0;
                width: 114px;
            }
        }
    }
}
@keyframes rotateBorderCalling {
    0% {
        transform: rotate(0);
    }
    100% {
        transform: rotate(360deg);
    }
}
@keyframes calling {
    0% {
        transform: rotate(-36deg);
        box-shadow: 0px 0px 30px 14px rgba(255, 255, 255, 0.9);
    }
    50% {
        transform: rotate(36deg);
        box-shadow: 0px 0px 30px 0px rgba(255, 255, 255, 0.9);
    }
    100% {
        transform: rotate(-36deg);
        box-shadow: 0px 0px 30px 14px rgba(255, 255, 255, 0.9);
    }
}
.call-remote-consultant {
    position: relative;
    margin-top: 30px;
    background-color: #e2eaf0;
    box-shadow: 0 0 26px -7px black;
    box-sizing: border-box;
    padding: 20px;
    display: flex;
    justify-content: space-between;

    .camera-video {
        width: 150px;
        height: 110px;
        border: 2px solid rgba(241, 241, 241, 1);
        border-radius: 6px;
        transform-origin: top right;
        position: absolute;
        top: 78px;
        right: 320px;
        z-index: 100;
        transform-origin: top right;
        overflow: hidden;

        transform-origin: top right;
        // transition: all 0.2s linear;
        .frame-video {
            .my-video-call {
                font-size: 24px;
                font-weight: 600;
                color: #cce6ff;
            }
            .video-call {
                width: 100%;
                height: 100%;
            }
        }
    }
    // your friend bideo
    .your-friend-call-video {
        width: 1024px;
        height: 630px;

        .your-friend-calling {
            height: 615px;
            border-radius: 12px;
            padding: 4px;
            .your-friend-name {
                font-size: 24px;
                font-weight: 600;
                color: #46d3cc;
                margin-bottom: 20px;

                span {
                    padding: 5px;
                    border-radius: 8px;
                    background-color: #e6f8fb;
                }
            }
            .video-call {
                height: 91%;
                width: 96%;
            }
            .video-call-muted::-webkit-media-controls-panel {
                display: none !important;
                opacity: 1 !important;
            }
            .turn-off-camera {
                width: 100%;
                height: 95%;
                position: relative;
                .img-turn-off-camera {
                    background-image: url("../../../assets/images/turn-off-camera.png");
                    height: 114px;
                    width: 114px;
                    background-size: contain;
                    background-repeat: no-repeat;
                    position: absolute;
                    top: 22%;
                    right: 44%;
                }
                .title-turn-off-camera {
                    position: absolute;
                    top: 48%;
                    width: 100%;
                    text-align: center;
                    font-size: 22px;
                    font-weight: 600;
                    color: #50ceff;
                }
            }
        }
        .nav-controll-call-video {
            position: absolute;
            bottom: 37px;
            left: -155px;
            width: 100%;
            padding-top: 10px;
            .controll-call-list {
                list-style: none;
                display: flex;
                align-items: center;
                justify-content: center;
                li {
                    width: 50px;
                    height: 50px;
                    cursor: pointer;
                    text-align: center;
                    background-color: #fff;
                    box-shadow: 0 0 10px 0px rgba(0, 0, 0, 0.1);
                    border-radius: 10px;
                    margin: 10px;
                    font-size: 20px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    transition: 0.5s;
                    color: #07c5b9;
                    &:hover {
                        transform: translateY(-10px);
                    }
                }
                .control-call-item-3 {
                    color: #fd494a;
                    i {
                        transform: rotate(225deg);
                    }
                }
            }
        }
    }
    .chat-box-area {
        background-color: #ffffff;
        width: 272px;
        height: 500px;
        padding: 15px 10px 0;
        margin-top: 100px;
        border-radius: 12px;
        display: flex;
        flex-direction: column;
        box-shadow: 0 0 10px 0px rgba(0, 0, 0, 0.1);
        .title-chat-box {
            height: 40px;
            border-bottom: 1px solid rgba(0, 0, 0, 0.9);
            span {
                font-size: 20px;
                font-weight: 600;
                font-family: Verdana, Geneva, Tahoma, sans-serif;
            }
        }
        .chat-content {
            height: 83%;
            padding-top: 10px;
            display: flex;
            flex-direction: column;

            .scroll-text-bottom {
                height: 100%;
                width: 100%;
                overflow-x: hidden;

                // custom scroll bar
                ::-webkit-scrollbar-track {
                    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
                    background-color: #f5f5f5;
                }

                ::-webkit-scrollbar {
                    width: 6px;
                    background-color: #f5f5f5;
                }

                ::-webkit-scrollbar-thumb {
                    background-color: #000000;
                }
            }
            .orther-text {
                display: flex;
                width: 88%;
                margin-bottom: 10px;
                align-items: flex-end;
                .orther-mess-avt {
                    background: url("https://i.pinimg.com/originals/63/6f/c4/636fc4782e0e838cbae9039b21f5cf9d.png");
                    height: 50px;
                    width: 50px;
                    background-size: cover;
                    background-position: center;
                    border-radius: 50%;
                    cursor: pointer;
                }
                .orther-mess-content {
                    max-width: 70%;
                    margin-left: 14px;
                    overflow-wrap: break-word;
                    .content-mess {
                        width: 100%;
                        background-color: #6ae432;
                        border-radius: 8px;
                        padding: 6px;
                    }
                    .time-mess {
                        padding-left: 5px;
                        font-size: 12px;
                        color: #000000;
                    }
                }
            }
            .my-text {
                display: flex;
                justify-content: flex-end;
                width: 100%;
                margin-bottom: 10px;
                .my-text-container {
                    width: 80%;
                    display: flex;
                    align-items: flex-end;
                    justify-content: flex-end;
                    .my-mess-content {
                        max-width: 71%;
                        margin-right: 10px;
                        overflow-wrap: break-word;
                        .my-mess {
                            width: 100%;
                            background-color: #27e7da;
                            border-radius: 8px;
                            padding: 6px;
                        }
                        .time-my-mess {
                            display: flex;
                            justify-content: flex-end;
                            font-size: 12px;
                            color: #000000;
                        }
                    }
                    .my-mess-avt {
                        background: url("https://i.pinimg.com/736x/00/54/da/0054dacd786d58e8705f34e96d037539.jpg");
                        height: 50px;
                        width: 50px;
                        background-size: cover;
                        background-position: center;
                        border-radius: 50%;
                        cursor: pointer;
                    }
                }
            }
        }
        .type-chat {
            height: 70px;
            padding: 10px;
            .input-mess-content {
                position: relative;
                width: 100%;
                border: 1px solid rgb(129, 127, 255);
                border-radius: 8px;
                padding: 2px;
                input {
                    border: none;
                    height: 40px;
                    width: 180px;
                    &::placeholder {
                        color: black;
                    }
                }
                .icon-send-mess {
                    position: absolute;
                    top: 4px;
                    right: 10px;
                    width: 35px;
                    height: 35px;
                    background-color: #07c5b9;
                    text-align: center;
                    border-radius: 10px;
                    cursor: pointer;
                    i {
                        font-size: 18px;
                        line-height: 35px;
                        color: white;
                        transform: translateX(-1px);
                    }
                }
            }
        }
    }
}

// doctor info

.space-footer {
    height: 500px;
}
.call-consultant {
    z-index: 1;
    position: relative;
    font-size: 16px;
    font-family: inherit;
    color: white;
    padding: 0.5em 1em;
    outline: none;
    border: none;
    background-color: hsl(236, 32%, 26%);
}

.call-consultant:hover {
    cursor: pointer;
}

.call-consultant::before {
    content: "";
    z-index: -1;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    border: 4px solid hsl(236, 32%, 26%);
    transform-origin: center;
    transform: scale(1);
}

.call-consultant:hover::before {
    transition: all 0.75s ease-in-out;
    transform-origin: center;
    transform: scale(1.75);
    opacity: 0;
}

@keyframes openCameraVideo {
    0% {
        transform: scale(0, 0);
    }
    50% {
        transform: scale(0.5, 0.5);
    }
    90% {
        transform: scale(1.1, 1.1);
    }
    100% {
        transform: scale(1, 1);
    }
}
@keyframes closeCameraVideo {
    0% {
        opacity: 1;
        transform: scale(1, 1);
    }
    50% {
        transform: scale(0.5, 0.5);
        opacity: 0.5;
    }

    100% {
        transform: scale(0, 0);
        opacity: 0;
    }
}
