@import "../styles/common.scss";
.main-container {
    background-color: #fff !important;
}
body {
    font-size: 14px;
    overflow-x: hidden;
    font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
    // custom scroll bar
    &::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        background-color: #f5f5f5;
    }

    &::-webkit-scrollbar {
        width: 6px;
        background-color: #f5f5f5;
    }

    &::-webkit-scrollbar-thumb {
        background-color: #34536d;
    }
}
a {
    color: #337ab7;
    text-decoration: none;
}
